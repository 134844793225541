.authincation-content {
  background: #185cabe6;
  box-shadow: 0 0 35px 0 rgb(154 161 171 / 15%);
  border-radius: 15px;
}
.auth-form{
  padding: 50px 50px;
}
.loginbody{
  background: url('../src/assets/back.png');
  /* background-position: center; */
  background-size: 100% 100%;
  /* height: 100vh; */
  font-weight: 600;
  position: fixed;
  height: 100%;
  width: 100%;
  overflow: auto;
}
.form-controllogin{
  border-radius: 1.25rem ;
  background: #fff;
  border: 1px solid #f0f1f5 !important;
  color: #B1B1B1 !important;
  height: 56px;
}
.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.3125rem;
  padding-left: 1.5rem;
  align-items: center;
}
.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.15625rem;
  /* opacity: 0; */
}
a{
  text-decoration: none !important;
}
.btnlogin{
  padding: 0.938rem 1.5rem !important;
  border-radius: 1.25rem !important;
  font-weight: 700 !important;
  font-size: 1rem !important;
  width: 100%;
  color: #0B2A97 !important;
}

.boxicon-container {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  cursor: pointer;
  position: relative;
  align-items: center;
}

.boxicon {
  margin: 0.8rem;
  animation-iteration-count: 1;
  z-index: 2;
}

.first-and-last-trash-fix {
  /* margin: 0.8rem; */
  margin: 1.1rem;
}

/* .active {
  /* background-color: red; 
} */

.active::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 95%;
  opacity: 1;
  z-index: -1;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem;
  border-right: 7px solid #0B2A97;
  background: rgba(11, 42, 151, 0.1);
}

.boxicon::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 95%;
  opacity: 0;
  z-index: -1;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem;
  border-right: 7px solid #0B2A97;
  background: rgba(11, 42, 151, 0.1);
}

.boxicon-container:hover > .boxicon::after {
  opacity: 1;
  transition: 0.1s;
}

.sidebar {
  min-height: 100%;
  /* height: 100%; */
  /* height: 102vh; */
  /* width: 4.2rem; */
  width: 3.6rem;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  transition: 0.3s width;
  /* margin-top: -56px; */
  margin-top: -90px;
  box-shadow: -7px 0px 16px 0px;
  z-index: 1 !important;
  padding: 0;
  position: fixed;
}

.sidebar > *:first-child {
  /* margin-top: 1rem;
  margin-bottom: 1rem; */
  /* height: 55px; */
  /* height: 150px; */
  height: 90px;
  /* margin-top: 45px; */
}
/* .sidebar > *:last-child {
  margin-top: auto;
  margin-bottom: 2rem;
} */

@media screen and (max-width: 850px){
  /* .sidebar > .boxicon-container:not(:first-child) {
    display: none !important;
  } */

  
  /* .show-description img {
    display: none;
  } */
}

.final-icon {
  background-color: red;
}

.description {
  opacity: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 5rem;
  z-index: 10;
  font-weight: bold;
  margin: 0;
  color: black;
  transition: 0.2s opacity;
}

.active-description {
  color: black;
}

.expanded {
  /* width: 13rem; */
  /* width: 20rem; */
  /* width: 23.6rem; */
  /* width: 366px; */
  /* width: 40rem; */
  align-items: flex-start;
  padding-left: 30px !important;
  /* height: 102vh; */
  min-width: 294px;
}

.show-description {
  opacity: 1;
}

.container {
  display: flex;
}

.text {
  color: white;
  z-index: 100;
  margin: 0 auto;
  align-self: center;
  font-size: 32px;
}

.bg-light{
  background-color: #ffffff !important;
  box-shadow: 0px -7px 16px 0;
}

@media screen and (max-height: 550px) {
  .boxicon {
    margin-left: 1.2rem;
  }
  .first-and-last-trash-fix {
    margin-left: 1.2rem;
  }
}

.atoz{
  padding-top: 30px !important;
  display: flex !important;
  align-items: center;
}

.addrolebtn {
  width: auto;
}

.cardheadper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.backbtn{
  display: flex !important;
  font-size: 21px !important;
  align-items: center !important;
}
.backicon{
  fill: #fff !important;
  margin-right: 6px;
}






.patientform{
    min-height: 50px;
    padding: 30px;
    box-shadow: 3px 3px 15px -5px rgb(0 0 0 / 50%);
    border-radius: 10px;
}
.core-container{
    padding: 50px;
}
.core-container label{
    padding: 5px;
}
.core-container .form-group{
    padding-top: 20px;
}
.emojis{
    border: none;
    margin: 15px;
}
.clickedemoji{
    border: 5px solid;
    border-radius: 50%;
}
.splin{
    width: 80px !important;
    display: inline !important;
    margin-left: 20px;
}
h1{
    padding: 6px 0px 2px 0px;
    margin-bottom: 10px;
}
@media (max-width: 768px){
    .core-container{
      padding: 15px;
  }
  .splin{
      width: 80px !important;
      display: inline !important;
      margin-left: 5px;
  }

}



.hoMdai {
  max-height: fit-content !important;
}


/* add patient imgae points css */
.image-marker__marker--default {
  width: 25px;
  height: 25px;
  background-color: transparent !important;
  border-radius: 50%;
  color: white;
  text-align: center;
  border: 2px solid black;
}
/* add patient imgae points css */


.TpgDh {
  z-index: auto !important;
}

.activepd {
  background: #c9c9c9;
}


.cOUORS {
  z-index: auto !important;
}



.badgesecond {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
}

.badge-success {
  color: #fff;
  background-color: #28a745;
}

.badge-danger {
  color: #fff;
  background-color: #dc3545;
}

.badge-warning {
  color: #212529;
  background-color: #ffc107;
}

.badge-reschdule {
  color: #fff;
  background-color: #ff6d7b;
}


.pdcss {
  margin-left: 220px;
  padding : 5px;
}


.orthotable tr td {
  margin: 0;
  padding: 0;
  line-height: 12.5px;
  border: 1px solid;
}
.orthotable{
  margin: auto !important;
  margin-top: 5px !important;
  width: 98% !important;
}

.orthoinput {
  border: 0;
  border-bottom: 1px solid #000000;
  margin:  0 0.5rem 0 0.5rem ;
}
.orthoinputred {
  border: 0;
  border-bottom: 1px solid #000;
  margin: 0 0.5rem 0 0.5rem;
  color: red;
  /* border-bottom: 1px solid #f13239; */
}

.orthoinputblack {
  border: 0;
  border-bottom: 1px solid #000;
  margin: 0 0.5rem 0 0.5rem;
  color: black;
}

.orthoinputblue {
  border: 0;
  border-bottom: 1px solid #000;
  margin: 0 0.5rem 0 0.5rem;
  color: blue;
}


.fc-scroller {
  z-index: 0 !important;
}


.selecttid {
  width: 200px !important;
}




.sidebarinside {
  width: 220px;
  border-width: 0px 1px 1px;
  border-style: solid;
  border-color: rgb(222, 226, 230);
  border-image: initial;
  position: absolute;
  height: 75%;
}

.sidebarinside.collapsedinside {
  width: 50px;
}
.sidebarinside.collapsedinside .menumainname {
  display: none;
}
.sidebarinside.collapsedinside span {
  display: none;
}


.sigCanvas {
  border: 1px solid #000;
}




.tooltip {
  /* position: relative; */
  display: inline !important;
  /* border-bottom : 1px dotted black !important; */
  opacity : 1 !important;
}

.tooltip .tooltiptext {
    visibility: hidden  !important;
    width: 310px;
    background-color: #fff !important;
    color: #000;
    /* text-align: center; */
    border-radius: 6px !important;
    padding: 10px !important;
    /* Position the tooltip */
    /* position: absolute;
    z-index: 1; */
    position: absolute !important;
    z-index: 1 !important;
    /* top: 50px; */
    left: 890px;
    /* margin: 100px; */
    box-shadow: 1px 3px 10px -2px;
    margin-top: -15px
}
.tooltip:hover .tooltiptext {
    visibility: visible !important;
}


.pnamehoverdash:hover {
  color: #30CBE5;
  text-decoration: underline;
}


.btndashcheck {
  font-size: 11px;
  font-weight: 700;
  color: #414146;
  width: 70px;
  height: 22px;
  border-radius: 3px;
  border: 1px solid #979797;
  text-align: center;
  cursor: pointer;
}

.fc-toolbar-title {
  font-size: 15px !important;
}

.fc-col-header-cell-cushion {
  font-size: 11px !important;
}
.fc-prev-button, .fc-next-button, .fc-event-title, .fc-today-button, .fc-timegrid-slot-label-cushion, .fc-col-header-cell-cushion, .fc-event-time {
  font-size: 11px !important;
}

.fc-today-button {
  text-transform: capitalize !important;
}

.fc-event {
  border-width: 1px 1px 1px 3px;
}